import { createIntlCache, createIntl, IntlConfig } from 'react-intl';

import { flattenObject } from "../../../helpers/generic-helpers.helper";
import localeENGB from './locale-en_GB.json';

// Define locale config
const i18nConfig: IntlConfig = {
	locale: 'en-GB',
	messages: flattenObject(localeENGB),
};

// Create a cache for react-intl plugin
export const i18nCache = createIntlCache();

// Create instance of react-intl + export it
export const intl = createIntl(i18nConfig, i18nCache);

export default i18nConfig;
