import { createSlice, Dispatch } from '@reduxjs/toolkit';

import { II18nState, ITranslation } from './i18n.types';

import { flattenObject } from 'helpers/generic-helpers.helper';
import { IFlattenObjectInput } from 'helpers/helpers.types';

// Create initial state
export const initialI18nState: II18nState = {
	translations: [],
	localeRetrieved: 'en-GB',
	loading: false,
};

const i18nSlice = createSlice({
	name: 'i18n',
	initialState: initialI18nState,
	reducers: {
		GET_TRANSLATIONS_FOR_LOCALE(state, action) {
			return {
				...state,
				localeRetrieved: action.payload.locale,
				loading: true,
			};
		},
		GET_TRANSLATIONS_FOR_LOCALE_SUCCESS(state, action) {
			const { data } = action.payload;
			const { localeRetrieved } = state

			const translation: ITranslation = {
				locale: localeRetrieved,
				messages: flattenObject(data as IFlattenObjectInput),
			}

			return {
				...state,
				translations: [translation],
				loading: false,
			};
		},
		GET_TRANSLATIONS_FOR_LOCALE_FAIL(state, action) {
			return {
				...state,
				loading: false,
			};
		},
	},
});

export const getTranslationsForLocale = (locale: string) => async (dispatch: Dispatch) => {
	const [lang, country] = locale.split('-');
	await dispatch(
		GET_TRANSLATIONS_FOR_LOCALE({
			locale,
			request: {
				method: 'get',
				url: `1/public/localisation/${lang}${country ? `?countryCode=${country}` : ''}&type=consumer`,
			}
		})
	)
}

export const { GET_TRANSLATIONS_FOR_LOCALE } = i18nSlice.actions;

export default i18nSlice.reducer;
