import { createSlice, Dispatch } from '@reduxjs/toolkit';

import { IHttpState, Region } from "./http.types";

export const initialHttpState: IHttpState = {
	recentServerRegion: null
}

const httpSlice = createSlice({
	name: 'http',
	initialState: initialHttpState,
	reducers: {
		SET_RECENT_SERVER_REGION(state, action) {
			return {
				...state,
				recentServerRegion: action.payload,
			}
		},
		CLEAR_RECENT_SERVER_REGION(state) {
			return {
				...state,
				recentServerRegion: null,
			}
		}
	}
})

// Destructure and export the plain action creators
export const {
	SET_RECENT_SERVER_REGION,
	CLEAR_RECENT_SERVER_REGION,
} = httpSlice.actions

/** The returned server region from the most recent request */
export const setRecentServerRegion =
	(region: Region | null) => async (dispatch: Dispatch) => {
		await dispatch(SET_RECENT_SERVER_REGION(region))
	};

export const clearRecentServerRegion =
	() => async (dispatch: Dispatch) => {
		await dispatch(CLEAR_RECENT_SERVER_REGION())
	}

export default httpSlice.reducer;
