import { combineReducers, AnyAction } from '@reduxjs/toolkit';

import loadingReducer from 'components/loading/loading.slice';
import customerReducer, {
	initialCustomerState,
} from 'modules/core/customer/customer.slice'
import httpReducer, {
	initialHttpState,
} from 'modules/core/http/http.slice';
import i18nReducer from 'modules/core/i18n/i18n.slice';
import paymentReducer, {
	initialPaymentState,
} from 'modules/core/payment/payment.slice';
import storeVersionReducer from 'modules/core/state/store-version/store-version.slice';
import themeReducer, {
	initialThemeState,
} from 'modules/core/theme/theme.slice';
import paymentRequestReducer, {
	initialPaymentRequestState,
} from 'modules/payment-request/payment-request.slice';
import subscriptionPlanReducer, {
	initialSubscriptionPlanState,
} from 'modules/subscription-plan/subscription-plan.slice';
import subscriptionReducer, {
	initialSubscriptionState,
} from 'modules/subscription/subscription.slice';

// Define root reducer
const combinedReducers = combineReducers({
	loading: loadingReducer,
	storeVersion: storeVersionReducer,
	theme: themeReducer,
	customer: customerReducer,
	payment: paymentReducer,
	paymentRequest: paymentRequestReducer,
	i18n: i18nReducer,
	subscriptionPlan: subscriptionPlanReducer,
	subscription: subscriptionReducer,
	http: httpReducer,
});

// Create and export a type for root reducer
export type RootState = ReturnType<typeof combinedReducers>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const rootReducer = (state: any, action: AnyAction) => {
	// Create current state varible
	let currentState = state;
	// if logout, set state to undefined
	action.type === 'auth/LOGOUT' && (currentState = undefined);
	// If new version, keep user logged in but reset other stores
	action.type === 'storeVersion/NEW_VERSION' &&
		(currentState = {
			...state,
			theme: initialThemeState,
			customer: initialCustomerState,
			paymentRequest: initialPaymentRequestState,
			payment: initialPaymentState,
			subscriptionPlan: initialSubscriptionPlanState,
			subscription: initialSubscriptionState,
			http: initialHttpState,
		});

	// If reset loading states, set events in progress to 0
	action.type === 'loading/RESET_LOADING_STATES' &&
		(currentState = {
			...state,
			loading: {
				...state.loading,
				eventsInProgress: 0,
			},
			customer: {
				...state.customer,
				eventsInProgress: 0,
			},
			payment: {
				...state.payment,
				eventsInProgress: 0,
			},
			paymentRequest: {
				...state.paymentRequest,
				eventsInProgress: 0,
			},
			subscriptionPlan: {
				...state.subscriptionPlan,
				eventsInProgress: 0,
			},
			subscription: {
				...state.subscription,
				eventsInProgress: 0,
			},
		});
	// return root reducer
	return combinedReducers(currentState, action);
};

export default rootReducer;
